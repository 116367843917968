//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProductModel } from '@/modules/product/product-model';
import i18n from '@/vueI18n';

const { fields } = ProductModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.brand,
  fields.barcode,
  fields.features,
  fields.quantity,
  fields.warrantyPeriod,
  fields.photo,
  fields.productParts,
  fields.qrCode,
]);


export default {
  name: 'app-product-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      step: 1,
      model: null,
      active: false,
      rules: formSchema.rules(),
      sparePartOptions: this.sparePartList,
    };
  },

  async created() {
    this.model = formSchema.initialValues(this.record || {});
    if (!this.isEditing) {
      this.model.warrantyPeriod = 0
      this.model.quantity = 0
    } else if (this.model.photo && !Array.isArray(this.model.photo)) {
      this.model.photo = [this.model.photo]
    }
    await this.doFetchSparePart();
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      is_screen_xs: 'layout/is_screen_xs',
      sparePartRows: 'sparePart/list/rows',
    }),
    language(){
      return i18n.locale
    },

    fields() {
      return fields;
    },
    sparePartList() {
      return this.sparePartRows.map(item => {
        return {
          label: item.name,
          value: item.id, 
        }
      })
    },
    productPartsPreview() {
      return this.model.productParts.map(item => {
        const temp = this.sparePartList.filter(el => el.id == item.id)
        return temp.length ? temp[0].label : 'not Found label of item'
        // return {
        //   label: temp.length ? temp[0].label : 'not Found label of item',
        //   value: item.id, 
        // }
      })
    },
    UrlExample() {
      return this.language == 'ar'
        ? 'مثال: https://www.puricomegypt.com/ro-system'
        : 'Example: https://www.puricomegypt.com/ro-system'
    },
  },

  methods: {
    ...mapActions({
      doFetchSparePart: 'sparePart/list/doFetch',
    }),
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.$emit('cancel');
    },
    OnClear() {
      this.model.productParts = []
    },
    removeItem(index) {
      this.model.productParts.splice(index, 1);
    },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.sparePartOptions = this.sparePartList
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.sparePartOptions = this.sparePartList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }

      if (this.step < 3) {
        return this.$refs.stepper.next()
      }

      const { id, ...values } = formSchema.cast(this.model);
      this.model.photo.length
        ? values.photo = this.model.photo[0]
        : values.photo = null
      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
  watch: {
    model: {
      handler(val){
         console.log('val: ', val)
        if (this.model.warrantyPeriod < 0) {
          this.model.warrantyPeriod = 0
        } 
        if (this.model.quantity < 0) {
          this.model.quantity = 0
        } 
      },
      deep: true
    },
  },
};
